import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

import Room from "@/classes/Room"

let db = getFirestore()
let collectionName = "licences"

class Licence{
    constructor(id, name, userAmount = 0, maxUserAmount = 10){
        this.id = id
        this.name = name
        this.userAmount = userAmount
        this.maxUserAmount = maxUserAmount
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let licences = []

        response.forEach(document => {
            let data = document.data()
            let tmp_licence = new Licence(document.id, data.name, data.userAmount, data.maxUserAmount)
            licences.push(tmp_licence)
        })

        return licences
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let licence = new Licence(response.id, data.name, data.userAmount, data.maxUserAmount)

        return licence
    }

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let licences = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_licence = new Licence(document.id, data.name, data.userAmount, data.maxUserAmount)
                licences.push(tmp_licence)
            })
            
            if(callback != null){
                callback(licences)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let licence = new Licence(snapshot.id, data.name, data.userAmount, data.maxUserAmount)
            
            if(callback != null){
                callback(licence)
            }
        })
        return unsub
    }

    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        if(this.id == null){
            let collectionRef = collection(db, collectionName)
            let response = await addDoc(collectionRef, {
                name: this.name,
                userAmount: this.userAmount,
                maxUserAmount: this.maxUserAmount,
            })
            this.id = response.id
        }else{
            let documentRef = doc(db, collectionName, this.id)
            await updateDoc(documentRef, {
                name: this.name,
                userAmount: this.userAmount,
                maxUserAmount: this.maxUserAmount,
            })
        }
    }

    async delete(){
        let rooms = await Room.getByLicence(this.id)
        for(let room of rooms){
            await room.delete()
        }

        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Licence