<template>
	<div v-if="new Array('Admin','SuperAdmin').includes(profile.role)">
        <v-card class="my-3">
            <v-card-title>Gerer les Utilisateurs</v-card-title>
            <v-card-text class="my-3">
                <v-data-table :headers="headers" :items="displayedUsers" item-key="name" :search="search">
                    <template v-slot:top>
                        <div class="d-flex flex-row-reverse flex-wrap align-center">
                            <v-col cols="12" sm="5" class="d-flex align-center py-0">
                                <v-select label="Room" :items="roomList" item-text="name" v-model="roomFilter" item-value="id"></v-select>
                                <v-btn icon small class="ml-3" @click="roomFilter = null">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="7" class="d-flex align-center pa-0 mb-3">
                                <v-text-field v-model="search" label="Rechercher" class="mx-4" />
                                <v-btn icon small class="mr-3" @click="search = null">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </div>
                    </template>
                    <template v-slot:item.avatar="{ item }">
                        <UserAvatar :profile="item"></UserAvatar>
                    </template>
                    <template v-slot:item.licence="{ item }" v-if="associationTable.licences">
                        <span v-if="item.role == 'None'" v-text="'Desactivé'"></span>
                        <span v-else-if="item.role == 'SuperAdmin'" v-text="'Toutes'"></span>
                        <span v-else v-text="associationTable.licences[item.licence].name"></span>
                    </template>
                    <template v-slot:item.rooms="{ item }" v-if="associationTable.rooms">
                        <span v-if="item.role == 'None'" v-text="'Desactivé'"></span>
                        <span v-else-if="item.role == 'SuperAdmin' || item.role == 'Admin' " v-text="'Toutes'"></span>
                        <span v-else-if="item.rooms != null && item.rooms.length != undefined" v-text="item.rooms.length"></span>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-btn icon color="secondary" @click="openConfigModal(item)">
                            <v-icon>mdi-account-cog-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="warning" @click="SendResetEmail(item.email)">
                            <v-icon>mdi-lock-reset</v-icon>
                        </v-btn>
                        <v-btn icon color="error" @click="deleteUser(item)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <ConfigModal ref="configModal" :profile="profile" :userBeforeChange="userBeforeChange" :modalData="configModalData" :roomList="roomList" :licenceList="licenceList" title="Configurer l'utilisateur" @save="handleSaveUser"></ConfigModal>
    </div>
</template>

<script>
    import logger from "@/assets/functions/logger"
    import { getAuth, sendPasswordResetEmail } from "firebase/auth"
    import ConfigModal from "@/components/Users/ConfigModal.vue"
	import Swal from 'sweetalert2/dist/sweetalert2.js'
    import UserAvatar from "@/components/UserAvatar.vue"

    import Profile from "@/classes/Profile"
    import Licence from "@/classes/Licence"
    import Room from "@/classes/Room"

	export default {
		name: "Users",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
        components: {
            ConfigModal,
            UserAvatar
        },
        data() {
            return {
                unsub: [],
                search: "",
                roomFilter: null,
                roleFilter: null,
                users: [],
                displayedUsers: [],
                licenceList: [],
                roomList: [],
                headers: [
                    { 
                        text: "Avatar", 
                        value: "avatar",
                        sortable: false,
                    },
                    { 
                        text: "Prenom", 
                        value: "firstname",
                        sortable: true,
                    },
                    { 
                        text: "Nom", 
                        value: "lastname",
                        sortable: true,
                    },
                    { 
                        text: "Email", 
                        value: "email",
                        sortable: true,
                    },
                    { 
                        text: "Role", 
                        value: "role",
                        sortable: true,
                    },
                    { 
                        text: "Licence", 
                        value: "licence",
                        sortable: true,
                    },
                    { 
                        text: "Rooms", 
                        value: "rooms",
                        sortable: true,
                    },
                    { 
                        text: "Action", 
                        value: "action",
                        sortable: false,
                    },
                ],
                configModalData: {},
                userBeforeChange: {},
                associationTable: {},
            }
        },
        watch: {
            roomFilter(newVal, oldVal) {
                if (newVal === null)
                    this.displayedUsers = this.users
                else
                    this.displayedUsers = this.users.filter(user=>user.rooms && user.rooms.includes(newVal))
            }
        },
        created() {
            this.unsub.push(Profile.listenAll(profiles => {
                this.users = []
                let tmp_users = []
                profiles.forEach(profile => {
                    if(this.profile.role == "SuperAdmin" ||  profile.licences.includes(this.profile.licence)) {
                        tmp_users.push(profile)
                    }
                })
                this.users = tmp_users
                this.displayedUsers = this.users
            }))

            this.unsub.push(Licence.listenAll(licences => {
                this.tmpLicenceList = []
                this.tmpLicenceTable = {}
                licences.forEach(licence => {
                    this.tmpLicenceList.push(licence)
                    this.tmpLicenceTable[licence.id] = licence
                })
                this.licenceList = this.tmpLicenceList
                this.associationTable.licences = this.tmpLicenceTable
            }))

            this.unsub.push(Room.listenAll(rooms => {
                this.tmpRoomList = []
                this.tmpRoomTable = {}
                rooms.forEach(room => {
                    this.tmpRoomList.push(room)
                    this.tmpRoomTable[room.id] = room
                })
                this.roomList = this.tmpRoomList
                this.associationTable.rooms = this.tmpRoomTable
            }))

            this.updateUsersAmount()
        },
        destroyed() {
            this.unsub.forEach(unsub => unsub())
        },
        methods: {
            openConfigModal(profile) {
                this.configModalData = JSON.parse(JSON.stringify(profile))
                this.userBeforeChange = this.configModalData
                this.$refs.configModal.open()
            },
            handleSaveUser(data){
                let tmp_profile = new Profile(data.id, data.uid, data.email, data.firstname, data.lastname, data.avatar, data.role, data.licence, data.licences, data.rooms, data.currentRoom, data.theme, data.fontSize, data.notification, data.notificationVolume, data.notifAreMuted, data.dev, data.envIsProd, data.commercial, data.color)
                tmp_profile.save()
                .then(()=>{
                    logger.log(this.profile.id, "USER UPDATED", this.profile.email + " a modifié l'utilisateur " + data.email)
                })
            },
            updateUsersAmount(){
                this.unsub.push(Profile.listenAll(profiles => {
                    let roomAmoutsTable = {}
                    let licenceAmoutsTable = {}
                    profiles.forEach(profile => {
                        if(profile.rooms){
                            for(let room of profile.rooms){
                                if(roomAmoutsTable[room] == undefined){
                                    roomAmoutsTable[room] = 1
                                }else{
                                    roomAmoutsTable[room]++
                                }
                            }
                        }
                        if(profile.licence){
                            if(licenceAmoutsTable[profile.licence] == undefined){
                                licenceAmoutsTable[profile.licence] = 1
                            }else{
                                licenceAmoutsTable[profile.licence]++
                            }
                        }
                    })
                    for(let roomId in roomAmoutsTable){
                        Room.updateById(roomId, {
                            userAmount: roomAmoutsTable[roomId]
                        })
                    }
                    for(let licenceId in licenceAmoutsTable){
                        Licence.updateById(licenceId, {
                            userAmount: licenceAmoutsTable[licenceId]
                        })
                    }
                }))
            },
            deleteUser(profile){
                Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if (result.isConfirmed) {
                        logger.log(this.profile.id, "USER DELETE", this.profile.email + " a supprimé l'utilisateur " + profile.email)
                        profile.delete()
					}
				})
            },
            SendResetEmail(email){
                Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if(result.isConfirmed){
                        sendPasswordResetEmail(this.auth, email, {url: window.location.origin})
                        .then( () => {
                            Swal.fire({
                                title: "MAIL ENVOYÉ",
                                text: "Un e-mail a été envoyé pour réinitialiser le mot de passe (pensez à vérifier le dossier spam).",
                                icon: "success",
                                confirmButtonText: "OK",
                            })
                        })
					}
				})
            }
        }
	}
</script>

<style>
thead {
    white-space: nowrap;
}
</style>
