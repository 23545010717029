<template>
	<v-dialog v-model="displayed" max-width="600px" scrollable persistent>
		<v-card>
			<v-card-title>
				<span class="text-h5" v-text="title"></span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12">
							<v-text-field label="Nom" v-model="modalData.lastname" required />
						</v-col>
						<v-col cols="12">
							<v-text-field label="Prenom" v-model="modalData.firstname" required />
						</v-col>
						<v-col cols="12">
							<v-text-field label="Email" v-model="modalData.email" disabled required />
						</v-col>
						<v-col cols="12">
							<v-select :items="roleList" v-model="modalData.role" label="Role"></v-select>
						</v-col>
						<v-col cols="12" v-if="['Corrector', 'Animator', 'Admin'].includes(modalData.role)">
							<v-select :items="currentLicence" item-text="name" item-value="id" v-model="modalData.licence" label="Licence"></v-select>
						</v-col>
						<v-col cols="12" v-if="['User', 'Corrector', 'Animator'].includes(modalData.role)">
							<v-select :items="currentRooms" item-text="name" item-value="id" v-model="modalData.rooms" attach chips label="Rooms" multiple></v-select>
						</v-col>
						<v-col cols="12" sm="6" v-if="modalData.role == 'SuperAdmin' && profile.commercial">
							<v-switch label="Commercial" color="warning" v-model="modalData.commercial"></v-switch>
						</v-col>
						<v-col cols="12" sm="6" v-if="modalData.role == 'SuperAdmin' && profile.dev">
							<v-switch label="Dev" color="error" v-model="modalData.dev"></v-switch>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="close"> Annuler </v-btn>
				<v-btn color="blue darken-1" text @click="save"> sauvegarder </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import Notification from '@/classes/Notification'

    import logger from "@/assets/functions/logger"
	import Swal from 'sweetalert2/dist/sweetalert2.js'

	export default {
		props: ['profile', 'userBeforeChange', 'modalData', 'title', 'roomList', 'licenceList'],
		data() {
			return {
				displayed: false,
				roleList: ["None", "User", "Corrector", "Animator", "Admin", "SuperAdmin"],
			}
		},
		methods: {
			open() {
				this.displayed = true
			},
			close() {
				this.displayed = false
			},
			save() {
                if(this.modalData && this.modalData.lastname && this.modalData.firstname && this.modalData.email && this.modalData.role && this.modalData.lastname.trim() != "" && this.modalData.firstname.trim() != "" && this.modalData.email.trim() != "") {
                    // SuperAdmin/None = no licences, no rooms
					if (['SuperAdmin', 'None'].includes(this.modalData.role)) {
						this.modalData.licence = null
						this.modalData.licences = null
						this.modalData.rooms = null
                    }
					// not SuperAdmin = no commercial, no dev
					else {
                        this.modalData.commercial = false
                        this.modalData.dev = false
					}
					// User/Corrector/Animator = licence for each of his rooms
					if (['User', 'Corrector', 'Animator'].includes(this.modalData.role)) {
						this.modalData.licences = [...new Set(
							this.modalData.rooms.map(roomId=>
								this.roomList.find(room=>room.id=roomId).licence
							)
						)]
					}
					// no more room = no more currentRoom
					if (!this.modalData.rooms.includes(this.modalData.currentRoom)) {
						this.modalData.currentRoom = (this.modalData.rooms.length === 0) ? null : this.modalData.rooms[0]
					}
					// User without room = None
					if (this.modalData.rooms.length === 0 && this.modalData.role === 'User') {
						this.modalData.role = 'None'
					}
					// delete notif for old room
					for (let oldRoom of this.userBeforeChange.rooms) {
						if (!this.modalData.rooms.includes(oldRoom)) {
							Notification.getByRoomAndByUser(oldRoom, this.modalData.id).then(notifications => {
								for (const key in notifications)
									for (const notif of notifications[key])
										notif.delete()
							})
						}
					}

                    this.$emit("save", this.modalData)
                    this.close()
                } else {
                    Swal.fire({
                        title: "Erreur",
                        text: "Veuillez remplir tous les champs",
						icon: "error",
                        confirmButtonText: "Ok"
                    })
                }
            },
		},
        computed: {
            currentLicence() {
                if(this.licenceList) {
                    return this.licenceList.filter(licence => licence.userAmount < licence.maxUserAmount || this.modalData.licence == licence.id)
                }
            },
            currentRooms() {
                if(this.modalData && this.modalData.licence) {
                    return this.roomList.filter(room => room.licence == this.modalData.licence)
                }
            }
        },
	}
</script>
